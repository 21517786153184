@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 786px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}


.formContainer{
  background-color: #BFDAD0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .formWrapper{
    background-color: white;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap:10px;
    align-items: center;

    .logo{
      color: #84C7AE;
      font-weight: bolder;
      font-size: 24px;
    }

    .title{
      color: #84C7AE;
      font-weight: bold;
      font-size: 12px;
    }

    form{
      display: flex;
      flex-direction: column;
      gap: 15px;

      input{
        padding: 15px;
        border: none;
        border-bottom: 1px solid #BFDAD0 ;
        outline: none;
        &::placeholder{
          color: rgb(175,175,175);
        }
      }
      button{
        background-color: #84C7AE;
        color: white;
        padding: 10px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
      }

      label{
        display: flex;
        align-items: center;
        gap: 10px;
        color: #758780;
        font-size: 12px;
        cursor: pointer;
      }

    }
    p{
      color: #758780;
      font-size: 12px;
      margin-top: 10px;
    }
  }

}


.home{
  background-color: #BFDAD0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container{
    border-radius: 5px;
    width: 65%;
    height: 80%;
    display: flex;
    overflow: hidden;
    @include tablet{
      width: 90%;
    }
    @include mobile{
      width: 90%;
    }

    .sidebar{
      flex: 1;
      border-right: 1px;
      background-color: #3A6957;
      position: relative;

        @include mobile{
          width: 95px;
        }

      .navbar{
        display: flex;
        align-items: center;
        background-color: #1D362D;
        height: 50px;
        padding: 10px;
        justify-content: space-between;
        color: white;

        .logo{
          font-weight: bolder;
          @include tablet{
            display: none;
          }
          @include mobile{
            display: none;
          }
        }

        .user{
          display: flex;
          gap: 10px;

          img{
            background-color: white;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            object-fit: cover;
          }

          button{
            background-color: #67877B;
            color: white;
            font-size: 10px;
            border: none;
            border-radius: 2px;
            cursor: pointer;
            @include tablet{
              position: absolute;
              bottom: 10px;
            }
            @include mobile{
               position: absolute;
               bottom: 10px;
             }

          }
        }

      }
      .search{
        border-bottom: 1px solid grey;

        .searchForm{
          padding: 10px;
          input{
            background-color: transparent;
            border: none;
            color: white;
            outline: none;

            &::placeholder{
              color: lightgray;

              @include mobile{
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }

      .userChat{
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        cursor: pointer;

        &:hover{
          background-color: #1D362D;
        }

        img{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;

          @include mobile{
            width: 30px;
            height: 30px;
          }
        }

        .userChatInfo{
          span{
            font-size: 18px;
            font-weight: 500;

            @include mobile{
              font-weight: 500;
              font-size: 14px;
            }
          }

          .chat{
            color: lightgrey;

            @include mobile{
              display: none;
            }
          }

          .convo{
            font-size: 14px;
            color: lightgray;

          }
        }
      }
    }
    .chat{flex: 2;
      @include mobile{
       flex: 3;
      }

    .chatInfo{
      height: 50px;
      background-color: #76AB97;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      color: White;
    }
      .chatIcons{
        display: flex;
        gap: 10px;

        img{
          height: 24px;
          cursor: pointer;
        }
      }

      .messages{
        background-color: #E0EBE7;
        padding: 10px;
        height: calc(100% - 160px);
        overflow: scroll;

        .message{
          display: flex;
          gap: 20px;
          margin-bottom: 20px;

          .messageInfo{
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;

            img{
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }


          .messageContent{
            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            p{
              background-color: white;
              padding: 10px 20px;
              border-radius: 0px 10px 10px 10px;
              max-width: max-content;
            }
            img{
              width: 50%;
            }
          }

          &.owner{
            flex-direction: row-reverse;

          .messageContent{
            align-items: flex-end;

            p{
              background-color: #3A6957;
              color: white;
              border-radius: 10px 0px 10px 10px;
            }
          }
          }
        }
      }

      .input{
        height: 50px;
        background-color: white;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input{
          width: 100%;
          font-size: 18px;
          border: none;
          outline: none;
          color: #696A69;

          &::placeholder{
            color: lightgrey;
          }
        }

        .send{
          display: flex;
          align-items: center;
          gap:10px;

          img{
            height: 24px;
            cursor: pointer;

          }

          button{
            border: none;
            padding: 10px 15px;
            color: white;
            background-color: #3A6957;
            border-radius: 3px;
            cursor: pointer;
          }
        }
      }
    }
  }
}